<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Sort each of the following elements by effective nuclear charge,
        <stemble-latex content="$\text{Z}_{\mathrm{eff}}$" />
        , from smallest to largest:
      </p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="itemList"
        class="mb-0"
        style="max-width: 400px"
        prepend-text="Largest"
        append-text="Smallest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput';

export default {
  name: 'Question29',
  components: {
    StembleLatex,
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
      itemList: ['$\\text{Sn}$', '$\\text{Rb}$', '$\\text{Sb}$', '$\\text{In}$', '$\\text{Sr}$'],
    };
  },
};
</script>
